import React from "react";
import { fontSizes } from "../../constants/colors";
import { useParams } from "react-router-dom";
import UploadedFiles from "../UploadedFiles/UploadedFiles";
import { useSelector } from "react-redux";
export default function ProjectFiles() {
  const { projectId } = useParams();
  const { invoices } = useSelector((state) => state.invoice);
  const { projects } = useSelector((state) => state.project);
  const { chatrooms } = useSelector((state) => state.chatrooms);

  const selectedInvoice = invoices?.find(
    (invoice) => invoice?.project?._id === projectId
  );

  const filterChatroomsByProject = (chatrooms, projectId) => {
    const matchedProjects = chatrooms?.filter(
      (chatroom) => chatroom?.project?._id === projectId
    );

    if (matchedProjects?.length === 0) {
      console.log(`No project found with ID: ${projectId}`);
      return [];
    }

    const result = matchedProjects?.map((chatroom) => ({
      conversationId: chatroom?._id,
      receiverId: chatroom?.representative?._id,
    }));

    return result;
  };

  const result = filterChatroomsByProject(chatrooms, projectId);


  const projectDetails =
    selectedInvoice?.project ||
    projects?.find((item) => item?._id === projectId);

  const categories = [
    { id: 1, name: "Manuscript" },
    { id: 2, name: "Cover Design" },
    { id: 3, name: "Additional Printing" },
    { id: 4, name: "Ebook" },
    { id: 5, name: "Other" },
  ];

  return (
    <div>
      <div className="flex  items-center bg-white rounded-[20px] p-[20px] space-x-4 shadow-xl">
        <h1 className={`font-bold ${fontSizes.text_xl}`}>
          {`${projectDetails && projectDetails?.projectName}`} Project Files
          <span className={`text-[#2CB9A8]  font-bold   ${fontSizes.text_xl}`}>
            .
          </span>
        </h1>
      </div>
      <div className="grid md:grid-cols-1 sm:grid-cols-1 fl:grid-cols-1 xl:grid-cols-2 w-full gap-5 lg:grid-cols-2 py-5">
        {categories?.map((item, index) => (
          <div
            key={index}
            className="flex w-full  bg-white rounded-[20px] p-[20px] space-x-4 shadow-xl"
          >
            <UploadedFiles
              category={item?.name}
              data={result}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
