import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProtectedRoute } from "protected-route-react";
import PropagateLoading from "./components/Loader/PropagateLoader ";
import ProjectFiles from "./components/ProjectFiles/ProjectFiles";
const ChargePayment = lazy(() => import("./pages/ChargePayment"));
const Login = lazy(() => import("./pages/Login"));
const FTLogin = lazy(() => import("./pages/FTLogin"));
const Signup = lazy(() => import("./pages/Signup"));
const InvoiceDwonload = lazy(() => import("./pages/InvoiceDownloadPage"));
const Layout = lazy(() => import("./components/Layout/Layout"));
const AddCustomerForm = lazy(() =>
  import("./components/AddCustomerForm/AddCustomerForm")
);
const TestingPage = lazy(() => import("./pages/TestingPage"));
const EditCustomerForm = lazy(() =>
  import("./components/EditCustomerForm/EditCustomerForm")
);
const ListCustomerForm = lazy(() =>
  import("./components/ListCustomerForm/ListCustomerForm")
);
const BillingList = lazy(() => import("./components/BillingList/BillingList"));
const AddPackageForm = lazy(() =>
  import("./components/AddPackageForm/AddPackageForm")
);
const EditPackageForm = lazy(() =>
  import("./components/EditPackageForm/EditPackageForm")
);
const ListPackages = lazy(() =>
  import("./components/ListPackages/ListPackages")
);
const MainChat = lazy(() =>
  import("./components/ReactChatUIKit/MainChat/MainChat")
);
const DisputeForm = lazy(() => import("./components/DisputeForm/DisputeForm"));
const Directory = lazy(() => import("./components/Directory/Directory"));
const CreateProjectForm = lazy(() =>
  import("./components/CreateProjectForm/CreateProjectForm")
);
const Payment = lazy(() => import("./components/Payment/Payment"));
const AdminDashboard = lazy(() =>
  import("./components/AdminDashboard/AdminDashboard")
);
const ClientDashboard = lazy(() =>
  import("./components/ClientDashboard/ClientDashboard")
);
const EditProject = lazy(() => import("./components/EditProject/EditProject"));
const ListProject = lazy(() => import("./components/ListProject/ListProject"));
const Invoicing = lazy(() => import("./pages/Invoicing"));
const AuthenticatedUser = lazy(() =>
  import("./components/AuthenticatedUser/AuthenticatedUser")
);
const Packages = lazy(() => import("./pages/Packages"));
const UserProfile = lazy(() => import("./components/UserProfile/UserProfile"));
const PaymentForm = lazy(() => import("./components/PaymentForm/PaymentForm"));
const Status = lazy(() => import("./pages/Status"));
const ForgetPassword = lazy(() => import("./pages/ForgetPassword"));
const PasswordReset = lazy(() =>
  import("./components/PasswordReset/PasswordReset")
);
const ManaagerDashboard = lazy(() =>
  import("./components/ManagerDashboard/ManaagerDashboard")
);
function App() {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { message, error } = useSelector((state) => state.user);
  const { packageMessage, packageError } = useSelector(
    (state) => state.package
  );
  const { projectMessage, projectError } = useSelector(
    (state) => state.project
  );

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearError" });
    }
    let insertMessageInToast = message || packageMessage || projectMessage;
    if (insertMessageInToast) {
      toast.success(insertMessageInToast);
      dispatch({ type: "clearMessage" });
    }
  }, [
    dispatch,
    error,
    message,
    packageMessage,
    packageError,
    projectError,
    projectMessage,
  ]);

  return (
    <>
      <Suspense
        fallback={
          <div className="h-full w-full flex justify-center items-center">
            <PropagateLoading text={""} />
          </div>
        }
      >
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                isAuthenticated ? (
                  <Navigate to={`/${user?.role}/default`} />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route path="/signup" element={<Signup />} />
            <Route path="/test" element={<TestingPage />} />
            <Route
              path="/login"
              element={
                <ProtectedRoute
                  isAuthenticated={!isAuthenticated}
                  redirect={`/${user?.role}/default`}
                >
                  <Login />
                </ProtectedRoute>
              }
            />
            <Route
              path="/FTLogin/:token"
              element={
                <ProtectedRoute
                  isAuthenticated={!isAuthenticated}
                  redirect={`/${user?.role}/default`}
                >
                  <FTLogin />
                </ProtectedRoute>
              }
            />
            <Route path="/password/reset" element={<PasswordReset />} />
            <Route
              path="/password/reset/:token"
              element={
                <ProtectedRoute
                  isAuthenticated={!isAuthenticated}
                  redirect={`/${user?.role}/default`}
                >
                  <ForgetPassword />
                </ProtectedRoute>
              }
            />
            <Route
              path="/password/create/:token"
              element={
                <ProtectedRoute
                  isAuthenticated={!isAuthenticated}
                  redirect={`/${user?.role}/default`}
                >
                  <ForgetPassword />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin"
              element={<AuthenticatedUser allowedUsers={"admin"} />}
            >
              <Route
                path="default"
                element={
                  <Layout>
                    <AdminDashboard />
                  </Layout>
                }
              />
              <Route
                path="customer"
                element={
                  <Layout>
                    <ListCustomerForm />
                  </Layout>
                }
              />
              <Route
                path="customer/add"
                element={
                  <Layout>
                    <AddCustomerForm />
                  </Layout>
                }
              />
              <Route
                path="customer/edit"
                element={
                  <Layout>
                    <EditCustomerForm />
                  </Layout>
                }
              />
              <Route
                path="charge/payment"
                element={
                  <Layout>
                    <ChargePayment />
                  </Layout>
                }
              />
              <Route
                path="package/add"
                element={
                  <Layout>
                    <AddPackageForm />
                  </Layout>
                }
              />
              <Route
                path="package/edit"
                element={
                  <Layout>
                    <EditPackageForm />
                  </Layout>
                }
              />
              <Route
                path="package"
                element={
                  <Layout>
                    <ListPackages />
                  </Layout>
                }
              />
              <Route
                path="project/add"
                element={
                  <Layout>
                    <CreateProjectForm />
                  </Layout>
                }
              />
              <Route
                path="project/edit"
                element={
                  <Layout>
                    <EditProject />
                  </Layout>
                }
              />
              <Route
                path="project"
                element={
                  <Layout>
                    <ListProject />
                  </Layout>
                }
              />
            </Route>

            <Route
              path="/projectmanager"
              element={<AuthenticatedUser allowedUsers={"projectmanager"} />}
            >
              <Route
                path="default"
                element={
                  <Layout>
                    <ManaagerDashboard />
                  </Layout>
                }
              />

              <Route
                path="project/add"
                element={
                  <Layout>
                    <CreateProjectForm />
                  </Layout>
                }
              />
              <Route
                path="project/edit"
                element={
                  <Layout>
                    <EditProject />
                  </Layout>
                }
              />
              <Route
                path="project"
                element={
                  <Layout>
                    <ListProject />
                  </Layout>
                }
              />
            </Route>

            <Route
              path="/customer"
              element={<AuthenticatedUser allowedUsers={"customer"} />}
            >
              <Route
                path="default"
                element={
                  <Layout>
                    <ClientDashboard />
                    {/* <ManaagerDashboard /> */}
                    {/* <AdminDashboard /> */}
                  </Layout>
                }
              />

              <Route
                path="billing"
                element={
                  <Layout>
                    <BillingList />
                  </Layout>
                }
              />
              <Route path="invoice/:id" element={<InvoiceDwonload />} />
              <Route
                path="packages"
                element={
                  <Layout>
                    <Packages />
                  </Layout>
                }
              />
              <Route
                path="directory"
                element={
                  <Layout>
                    <Directory />
                  </Layout>
                }
              />
              <Route
                path="feedback-form"
                element={
                  <Layout>
                    <DisputeForm />
                  </Layout>
                }
              />

              <Route
                path="payment"
                element={
                  <Layout>
                    <Payment />
                  </Layout>
                }
              />
              <Route
                path="payment/form"
                element={
                  <Layout>
                    <PaymentForm />
                  </Layout>
                }
              />

              <Route
                path="payment/:status"
                element={
                  <Layout>
                    <Status />
                  </Layout>
                }
              />
              <Route
                path="profile"
                element={
                  <Layout>
                    <UserProfile />
                  </Layout>
                }
              />
            </Route>

            <Route
              element={
                <AuthenticatedUser allowedUsers={" admin projectmanager"} />
              }
            >
              <Route
                path="/invoice/payment"
                element={
                  <Layout>
                    <Invoicing />
                  </Layout>
                }
              />
            </Route>
            <Route
              element={
                <AuthenticatedUser
                  allowedUsers={"customer admin projectmanager"}
                />
              }
            >
              <Route
                path="/support"
                element={
                  <Layout>
                    <MainChat />
                  </Layout>
                }
              />
            </Route>
            <Route
              element={
                <AuthenticatedUser
                  allowedUsers={"customer admin projectmanager"}
                />
              }
            >
              <Route
                path="/files/:projectId"
                element={
                  <Layout>
                    <ProjectFiles />
                  </Layout>
                }
              />
            </Route>
            <Route
              element={
                <AuthenticatedUser
                  allowedUsers={"customer admin projectmanager"}
                />
              }
            >
              <Route
                path="/profile"
                element={
                  <Layout>
                    <UserProfile />
                  </Layout>
                }
              />
            </Route>

            {/* <Route path="/FTLogin/:token" element={<FTLogin  />} /> */}
            {/* <Route path="/profile" element={ <ProtectedRoute isAuthenticated={isAuthenticated} redirect={'/sign-in'} >
        <Profile />
      </ProtectedRoute>} /> */}
          </Routes>

          <Toaster />
        </Router>
      </Suspense>
    </>
  );
}

export default App;
