import { createReducer } from "@reduxjs/toolkit";


export const servicesReducer = createReducer({}, {
    getServicesRequest: (state) => {
        state.loading = true
    },
    getServicesSuccess: (state, action) => {
        state.loading = false;
        state.services = action.payload.services || [];
    },
    getServicesFail: (state, action) => {
        state.loading = false
        state.servicesError = action.payload
    },
    clearError: (state) => {
        state.packageError = null
    },
    clearMessage: (state) => {
        state.servicesMessage = null
    }
})  