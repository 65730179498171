import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./Redux/store";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { SocketContext, socket } from "./components/Context/SocketContextt";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <SocketContext.Provider value={socket}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </SocketContext.Provider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
