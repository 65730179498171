import { BASE_URL } from "../../constants/constants";
import axios from "axios";

export const getChatrooms = (token) => async (dispatch) => {
  try {
    dispatch({ type: "getChatroomRequest" });
    const { data } = await axios.get(BASE_URL + "testChatRoom", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "getChatroomSuccess", payload: data });
  } catch (error) {
    dispatch({ type: "getChatroomFail", payload: error.response.data.message });
  }
};
export const getAdminChatrooms = (token) => async (dispatch) => {
  try {
    dispatch({ type: "getChatroomRequest" });
    const { data } = await axios.get(BASE_URL + "testChatRoom", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "getChatroomSuccess", payload: data });
  } catch (error) {
    dispatch({ type: "getChatroomFail", payload: error.response.data.message });
  }
};
export const assignChat = (token, conversationId) => async (dispatch) => {
  try {
    dispatch({ type: "assignChatRequest" });
    const { data } = await axios.put(
      BASE_URL + "assignChat",
      { conversationId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "assignChatSuccess", payload: data });
  } catch (error) {
    dispatch({ type: "assignChatFail", payload: error.response.data.message });
  }
};
