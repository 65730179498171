import { createReducer } from "@reduxjs/toolkit";

export const customerReducer = createReducer(
  {},
  {
    createCustomerRequest: (state) => {
      state.loading = true;
    },
    createCustomerSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    },
    createCustomerFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getAllCustomerRequest: (state) => {
      state.loading = true;
    },
    getAllCustomerSuccess: (state, action) => {
      state.loading = false;
      state.allCustomers = action.payload.users;
    },
    getAllCustomerFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    editCustomer: (state, action) => {
      state.editCustomer = action.payload.data;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  }
);
