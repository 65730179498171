export const colors = {
  primary: "#2CB9A8",
  white:"#ffffff",
  lightGray:'#BAC4C9',
  red:'red',
};
export const fontSizes = {
  base: 'xl:text-[16px] lg:text-[14px] md:text-[13px] sm:text-[12px] fl:text-[12px]',
  text_xl: "xl:text-[20px] lg:text-[18px] md:text-[15px] sm:text-[13px] fl:text-[13px]",
  text_2_xl: "xl:text-[24px] lg:text-[22px] md:text-[18px] sm:text-[16px] fl:text-[16px]",
  text_3_xl: "xl:text-[28px] lg:text-[26px] md:text-[25px] sm:text-[20px] fl:text-[18px]",

  text_5_xl: 'xl:text-[45px] lg:text-[37px]  md:text-[30px]   sm:text-[25px]  fl:text-[23px]',
  text_5P5_xl: 'xl:text-[50px] lg:text-[40px] md:text-[30px] sm:text-[25px] fl:text-[20px]',

  text_6_xl: "xl:text-[60px] lg:text-[50px]  md:text-[40px]   sm:text-[30px]  fl:text-[25px]",
  // text_6_xl: 'fl:text-[25px] sm:text-[30px]   md:text-[35px]  lg:text-[45px] xl:text-[55px] ',
  text_4_xl: 'xl:text-[35px] lg:text-[30px] md:text-[25px] sm:text-[20px] fl:text-[18px]',
  text_9_xl:"xl:text-9xl lg:text-[50px] md:text-[25px] sm:text-[20px] fl:text-[18px]"

}
