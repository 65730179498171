import { countries } from "./countryConstants";

// export const BASE_URL = "http://localhost:5000/api/v1/";
export const BASE_URL = "https://portal.americanseohub.com/api/v1/";
export function replaceCamelCase(input) {
  return input
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
}
// export const WEB_URL = "http://localhost:5000";
export const WEB_URL = "https://portal.americanseohub.com";
export const AVATAR_URL =
  "https://res.cloudinary.com/ddvtgfqgv/image/upload/v1691059545/member-profile/avatar_aoyxl6.webp";

export function detectAndConvertLinks(text) {
  const regex =
    /\b(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b(?:\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  return text.replace(regex, (match) => {
    // return text.replace(/(?:https?|ftp):\/\/[\n\S]+/g, (match) => {
    return `<a href="${match}" class="underline hover:text-blue-500" target="_blank">${match}</a>`;
  });
}
export function detectLinks(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (typeof value === "string" && value.match(/\bhttps?:\/\/\S+/gi)) {
        return true;
      }
    }
  }
  return false;
}

const findCountryObject = (mobileNumber, mobileNumberExceptCode) => {
  const matchedObject = countries.find((obj) => {
    const countryCodePrefix = mobileNumber.substring(0, 3);
    if (obj.phone === countryCodePrefix) {
      mobileNumberExceptCode = mobileNumber.substring(3, mobileNumber.length);
      return obj.phone === countryCodePrefix;
    } else {
      mobileNumberExceptCode = mobileNumber.substring(2, mobileNumber.length);
      const countryCodePrefix = mobileNumber.substring(0, 2);
      if (obj.phone === countryCodePrefix) {
        return obj.phone === countryCodePrefix;
      } else {
        const countryCodePrefix = mobileNumber.substring(0, 1);
        mobileNumberExceptCode = mobileNumber.substring(1, mobileNumber.length);
        return obj.phone === countryCodePrefix;
      }
    }
  });
  return matchedObject;
};
export function debounce(callback, delay) {
  let timerId;
  return function (...args) {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      callback.apply(this, args);
    }, delay);
  };
}
export const getCountryCode = (phoneNumber) => {
  let mobileNumber = phoneNumber.toString();
  let mobileNumberExceptCode = phoneNumber.toString();

  const matchedObject = findCountryObject(mobileNumber, mobileNumberExceptCode);

  if (matchedObject) {
    return { countryCode: matchedObject.phone, mobileNumberExceptCode };
  } else {
    console.error("No object");
  }
};

export const companyConstants = {
  companyName: "Bellevue Publishers",
  companyUrl: "https://bellevuepublishers.com/",
  billingEmail: "billing@bellevuepublishers.com",
  phoneNumber: "(877) 251-5759",
  companyAddress: "157 Church st 19th floor New haven, CT 06510 United States",
  cdnLink: "https://bellevue-portal.b-cdn.net/",
};
