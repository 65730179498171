import { createReducer } from "@reduxjs/toolkit";

export const projectReducer = createReducer({}, {
    getManagersRequest: (state) => {
        state.loading = true
    },
    getManagersSuccess: (state, action) => {
        state.loading = false;
        state.managers = action.payload.projectManagers
    },
    getManagersFail: (state, action) => {
        state.loading = false
        state.getManagersError = action.payload
    },
    getProjectsRequest: (state) => {
        state.loading = true
    },
    getProjectsSuccess: (state, action) => {
        state.loading = false;
        state.projects = action.payload.projects
    },
    getProjectsFail: (state, action) => {
        state.loading = false
        state.getProjectsError = action.payload
    },
    getMyProjectsRequest: (state) => {
        state.loading = true
    },
    getMyProjectsSuccess: (state, action) => {
        state.loading = false;
        state.myProjects = action.payload.projects
    },
    getMyProjectsFail: (state, action) => {
        state.loading = false
        state.getMyProjectsError = action.payload
    },
    editProject: (state, action) => {
        state.editProject = action.payload.data;
    },
    deleteProjectRequest: (state) => {
        state.loading = true
    },
    deleteProjectSuccess: (state, action) => {
        state.loading = false;
        state.projectMessage = action.payload.message
    },
    deleteProjectFail: (state, action) => {
        state.loading = false
        state.projectError = action.payload
    },

    clearError: (state) => {
        state.projectError = null
    },
    clearMessage: (state) => {
        state.projectMessage = null
    }
})  