import React, { useState, useEffect, useContext } from "react";
import { fontSizes } from "../../constants/colors";
import { FaDownload, FaFileAlt, FaFileImage, FaFilePdf } from "react-icons/fa";
import { PrimaryHeading } from "../comp/HeroHeading/HeroHeading";
import { Modal, Upload } from "antd";
import { PiFiles } from "react-icons/pi";
import Button from "../comp/Button/Button";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { BASE_URL, companyConstants } from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "../SweetAlert/SweetAlert";
import { getProjectFiles } from "../../Redux/actions/projectFilesAction";
import PropagateLoading from "../Loader/PropagateLoader ";
import { getChatrooms } from "../../Redux/actions/chatAction";
import { SocketContext } from "../Context/SocketContextt";
const { Dragger } = Upload;
export default function UploadedFiles({ category, data }) {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [numToShow, setNumToShow] = useState(5);
  const [showAll, setShowAll] = useState(false);
  const [open, setOpen] = useState(false);
  const { user, token } = useSelector((state) => state.user);
  const { projectFiles, loading } = useSelector((state) => state.projectFile);
  const socket = useContext(SocketContext);

  useEffect(() => {
    dispatch(getProjectFiles(token, projectId));
  }, [dispatch, token, projectId]);

  const uploadedFiles =
    projectFiles && projectFiles?.find((files) => files?._id === category);

  const toggleShowAll = () => {
    setShowAll(!showAll);
    setNumToShow(showAll ? 5 : uploadedFiles?.services?.length);
  };
  const handleFileSelection = (fileList) => {
    setSelectedFiles(fileList);
  };
  const handleSendMessage = async (data, link) => {
    try {
      for (const { receiverId, conversationId } of data) {
        const urlParts = link.split("/");
        const category = urlParts[urlParts.length - 2];

        const message = {
          text:
            "Recently Uploaded File" +
            " " +
            category +
            " " +
            selectedFiles?.map((item) => item?.name),
          conversationId: conversationId,
        };

        socket.emit("sendMessage", {
          senderId: user?._id,
          receiverId: receiverId,
          files:
            "Recently Uploaded File" + selectedFiles?.map((item) => item?.name),
          conversationId: conversationId,
        });

        const res = await axios.post(BASE_URL + "addMessage", message, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      setSelectedFiles(null);
      dispatch(getChatrooms(token));
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = () => {
    SweetAlert.loading("Uploading...");
    const formData = new FormData();
    selectedFiles.forEach((file, index) => {
      formData.append(`files`, file);
    });
    axios
      .post(BASE_URL + `projectFile/${projectId}/${category}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        handleSendMessage(data, response?.data?.uploadedFilesUrl);
        console.log(response.data);
        // setSelectedFiles(null);
        SweetAlert.success("Success", "File Uploaded Successfully");
        setOpen(false);
        dispatch(getProjectFiles(token, projectId));
      })
      .catch((error) => {
        SweetAlert.error("Error", error?.response?.data?.message);
        console.error("Error uploading files:", error);
      });
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const props = {
    name: "file",
    multiple: true,
    customRequest: dummyRequest,
    beforeUpload(info) {
      handleFileSelection(info);
    },
    onChange(info) {
      const originFileObjs = info.fileList.map((file) => file.originFileObj);
      handleFileSelection(originFileObjs);
    },

    onDrop(e) {
      handleFileSelection(e.dataTransfer.files);
    },
  };

  // const props = {
  //   name: "file",
  //   multiple: true,
  //   customRequest: dummyRequest,
  //   beforeUpload(file) {
  //     let allowedFileTypes;
  //     switch (category) {
  //       case "Manuscript":
  //         allowedFileTypes = ["application/pdf"];
  //         break;
  //       case "Cover Design":
  //         allowedFileTypes = ["image/png", "image/jpeg"]; // Adjust as needed
  //         break;
  //       case "Additional Printing":
  //         allowedFileTypes = [
  //           "text/csv",
  //           "application/msword",
  //           "	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //         ];
  //         break;
  //       case "Ebook":
  //         // Define allowed file types for Ebook category
  //         break;
  //       case "Other":
  //         // Define allowed file types for Other category
  //         break;
  //       default:
  //         allowedFileTypes = [];
  //     }

  //     const isAllowedFileType = allowedFileTypes.includes(file.type);
  //     if (!isAllowedFileType) {
  //       SweetAlert.warning(
  //         "Warning",
  //         `You can only upload ${allowedFileTypes.join(
  //           " or "
  //         )} files for ${category} category!`
  //       );
  //     }
  //     return isAllowedFileType;
  //   },
  //   onChange(info) {
  //     const files = info.fileList.filter((file) => {
  //       let allowedFileTypes;
  //       switch (category) {
  //         case "Manuscript":
  //           allowedFileTypes = ["application/pdf"];
  //           break;
  //         case "Cover Design":
  //           allowedFileTypes = ["image/png", "image/jpeg"]; // Adjust as needed
  //           break;
  //         case "Additional Printing":
  //           allowedFileTypes = [
  //             "application/msword",
  //             "	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //           ];
  //           break;
  //         case "Ebook":
  //           // Define allowed file types for Ebook category
  //           break;
  //         case "Other":
  //           // Define allowed file types for Other category
  //           break;
  //         default:
  //           allowedFileTypes = [];
  //       }
  //       return allowedFileTypes.includes(file.type);
  //     });
  //     const originFileObjs = files.map((file) => file.originFileObj);
  //     handleFileSelection(originFileObjs);
  //   },

  //   onDrop(e) {
  //     const files = Array.from(e.dataTransfer.files);
  //     const filteredFiles = files.filter((file) => {
  //       let allowedFileTypes;
  //       switch (category) {
  //         case "Manuscript":
  //           allowedFileTypes = ["application/pdf"];
  //           break;
  //         case "Cover Design":
  //           allowedFileTypes = ["image/png", "image/jpeg"]; // Adjust as needed
  //           break;
  //         case "Additional Printing":
  //           allowedFileTypes = [
  //             "application/msword",
  //             "	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //           ]; // Adjust as needed
  //           break;
  //         case "Ebook":
  //           // Define allowed file types for Ebook category
  //           break;
  //         case "Other":
  //           // Define allowed file types for Other category
  //           break;
  //         default:
  //           allowedFileTypes = [];
  //       }
  //       return allowedFileTypes.includes(file.type);
  //     });
  //     handleFileSelection(filteredFiles);
  //   },
  // };

  return (
    <div className="w-full">
      {loading && loading ? (
        <PropagateLoading />
      ) : (
        <div>
          {uploadedFiles && uploadedFiles?.files?.length > 0 && (
            <div className="flex justify-end px-5">
              <button
                className="border-[1px] text-gray-700 bg-[#E5E5E5] py-1 px-4 rounded-md   uppercase "
                onClick={() => setOpen(true)}
              >
                Upload
              </button>
            </div>
          )}

          {uploadedFiles && uploadedFiles?.files?.length > 0 ? (
            <div>
              <PrimaryHeading AdditionalStyle={"mb-0"}>
                Uploaded {category}'s
              </PrimaryHeading>
              <div className="grid fl:grid-cols-1 lg:grid-cols-3  gap-5">
                <div className="col-span-2 px-4">
                  <div className="col-span-1 mt-2 my-auto ">
                    <ul className="list-disc list-inside transition-all ease-in-out	 delay-500">
                      {uploadedFiles?.files
                        ?.slice(0, numToShow)
                        ?.map((item, index) => (
                          <div
                            key={index}
                            className="flex justify-between items-center"
                          >
                            <li className="flex items-center py-2 ">
                              <div>
                                <PiFiles
                                  className={`${fontSizes.base} mr-2 text-[#2CB9A8]`}
                                />
                              </div>
                              <p className="text-sm  font-light">
                                {item?.fileName}
                              </p>
                            </li>
                            <Link
                              to={companyConstants.cdnLink + item?.filePath}
                            >
                              <FaDownload
                                size={15}
                                className={`cursor-pointer text-[#2CB9A8]`}
                              />
                            </Link>
                          </div>
                        ))}
                    </ul>
                    {uploadedFiles?.files?.length > 5 && (
                      <button
                        onClick={toggleShowAll}
                        className="transition-all ease-in-out	 delay-500"
                      >
                        {showAll ? "See less" : "See more"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <PrimaryHeading AdditionalStyle={"mb-0"}>
                Upload {category}
              </PrimaryHeading>
              <Dragger {...props}>
                <div className="upload-container  rounded-md">
                  <div className="border-container  rounded-2xl  text-center">
                    <div className="flex items-center justify-center max-w-xs mx-auto text-[#2CB9A8] opacity-[0.25]">
                      <FaFileImage
                        className={` ${fontSizes.text_6_xl} transform -translate-x-6 -translate-y-2 scale-50 rotate-45`}
                      />
                      <FaFileAlt
                        className={` ${fontSizes.text_6_xl} ransform translate-y-4 scale-75`}
                      />
                      <FaFilePdf
                        className={` ${fontSizes.text_6_xl} transform translate-x-6 -translate-y-2 scale-50 -rotate-45`}
                      />
                    </div>
                    {/* <input type="file" id="file-upload"> */}
                    <p
                      className={`${fontSizes.base} text-indigo-900 font-semibold  mt-8 mb-0 opacity-75`}
                    >
                      Drag and drop files here, or
                      {/* <a
                        href="#"
                        className="text-[#2CB9A8] border-b-2 border-dotted "
                      >
                        {` `}browse
                      </a>
                      {" "} */}{" "}
                      <button className="text-[#2CB9A8] border-b-2 border-dotted">
                        browse{" "}
                      </button>{" "}
                      your computer.
                    </p>
                  </div>
                </div>
              </Dragger>
              {selectedFiles && selectedFiles.length > 0 && (
                <Button AdditionalStyle={"mt-5"} onClick={handleSubmit}>
                  Submit
                </Button>
              )}
            </>
          )}
        </div>
      )}

      <div>
        <Modal
          title={"Upload Files"}
          open={open}
          centered
          onCancel={() => setOpen(false)}
          footer={null}
          className="!my-20"
        >
          <Dragger {...props}>
            <div className="upload-container  rounded-md p-5">
              <div className="border-container border-[5px] border-dashed border-gray-200 rounded-2xl p-2 text-center">
                <div className="flex items-center justify-center max-w-xs mx-auto text-[#2CB9A8] opacity-[0.25]">
                  <FaFileImage
                    className={` ${fontSizes.text_9_xl} transform -translate-x-6 -translate-y-2 scale-50 rotate-45`}
                  />
                  <FaFileAlt
                    className={` ${fontSizes.text_6_xl} ransform translate-y-4 scale-75`}
                  />
                  <FaFilePdf
                    className={` ${fontSizes.text_9_xl} transform translate-x-6 -translate-y-2 scale-50 -rotate-45`}
                  />
                </div>
                {/* <input type="file" id="file-upload"> */}
                <p className="text-indigo-900 font-semibold text-lg mt-8 mb-0 opacity-75">
                  Drag and drop files here, or
                  <a
                    href="#"
                    className="text-[#2CB9A8] border-b-2 border-dotted "
                  >
                    {` `}browse
                  </a>{" "}
                  your computer.
                </p>
              </div>
            </div>
          </Dragger>
          {selectedFiles && selectedFiles.length > 0 && (
            <Button AdditionalStyle={"mt-5"} onClick={handleSubmit}>
              Submit
            </Button>
          )}
        </Modal>
      </div>
    </div>
  );
}
