import { configureStore } from "@reduxjs/toolkit";
import { BASE_URL } from "../constants/constants";
import { userReducer } from "./reducers/userReducer";
import { packageReducer } from "./reducers/packageReducer";
import { customerReducer } from "./reducers/customerReducer";
import { chatroomReducer } from "./reducers/chatReducer";
import { servicesReducer } from "./reducers/servicesReducer";
import { paymentReducer } from "./reducers/paymentReducer";
import { projectReducer } from "./reducers/projectReducer";
import { invoiceReducer } from "./reducers/invoiceReducer";
import { projectFileReducer } from "./reducers/projectFilesReducer";
export const server = BASE_URL;
const store = configureStore({
  reducer: {
    user: userReducer,
    package: packageReducer,
    customer: customerReducer,
    chatrooms: chatroomReducer,
    services: servicesReducer,
    payment: paymentReducer,
    project: projectReducer,
    invoice: invoiceReducer,
    projectFile: projectFileReducer,
  },
});

export default store;
