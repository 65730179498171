import { createReducer } from "@reduxjs/toolkit";

export const chatroomReducer = createReducer(
  {},
  {
    getChatroomRequest: (state) => {
      state.loading = true;
    },
    getChatroomSuccess: (state, action) => {
      state.loading = false;
      state.chatrooms = action.payload.chatRoomsWithLastMessages;
    },
    getChatroomFail: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    assignChatRequest: (state) => {
      state.loading = true;
    },
    assignChatSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    },
    assignChatFail: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    currentChat: (state, action) => {
      state.currentChat = action.payload.data;
    },
    onlineUsers: (state, action) => {
      state.onlineUsers = action.payload.data || [];
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  }
);
