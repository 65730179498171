import { BASE_URL } from "../../constants/constants";
import axios from "axios";

export const getProjectFiles = (token, projectId) => async (dispatch) => {
  try {
    dispatch({ type: "getProjectFileRequest" });
    const { data } = await axios.get(BASE_URL + `projectFiles/${projectId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: "getProjectFileSuccess", payload: data });
  } catch (error) {
    dispatch({
      type: "getProjectFileFail",
      payload: error.response.data.message,
    });
  }
};
