import React from "react";
import { fontSizes } from "../../../constants/colors";

function Button({ children, onClick, AdditionalStyle }) {
  return (
    <button
      className={`border-[1px] hover:border-[#07ae98] hover:bg-white  hover:text-[#07ae98]  bg-[#07ae98] py-1 px-2  md:px-4 rounded-xl  delay-75 transition-colors ${fontSizes.base} text-[#fff] lg:w-full uppercase ${AdditionalStyle} `}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default Button;
