import { createReducer } from "@reduxjs/toolkit";

export const projectFileReducer = createReducer(
  {},
  {
    getProjectFileRequest: (state) => {
      state.loading = true;
    },
    getProjectFileSuccess: (state, action) => {
      state.loading = false;
      state.projectFiles = action.payload.projectFiles;
    },
    getProjectFileFail: (state, action) => {
      state.loading = false;
      state.getProjectFileError = action.payload;
    },
    clearError: (state) => {
      state.projectFileError = null;
    },
    clearMessage: (state) => {
      state.projectFileMessage = null;
    },
  }
);
