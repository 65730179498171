import { createReducer } from "@reduxjs/toolkit";

export const invoiceReducer = createReducer(
  {
    invoices: [],
    allInvoices: [],
  },
  {
    getAllInvoiceRequest: (state) => {
      state.loading = true;
    },
    getAllInvoiceSuccess: (state, action) => {
      state.loading = false;
      state.allInvoices = action.payload.invoices || [];
    },
    getAllInvoiceFail: (state, action) => {
      state.loading = false;
      state.invoiceError = action.payload;
    },
    getInvoiceRequest: (state) => {
      state.loading = true;
    },
    getInvoiceSuccess: (state, action) => {
      state.loading = false;
      state.invoices = action.payload.invoices || [];
    },
    getInvoiceFail: (state, action) => {
      state.loading = false;
      state.invoiceError = action.payload;
    },
    selectedInvoice: (state, action) => {
      // state.loading = false
      state.selectedInvoice = action.payload;
    },
    clearError: (state) => {
      state.invoiceError = null;
    },
    clearMessage: (state) => {
      state.invoiceMessage = null;
    },
  }
);
