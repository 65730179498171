import { createReducer } from "@reduxjs/toolkit";

export const userReducer = createReducer(
  {
    user: JSON.parse(localStorage.getItem("user")),
    token: JSON.parse(localStorage.getItem("token")),
    error: null,
    page: JSON.parse(
      localStorage.getItem("page") ? localStorage.getItem("page") : "1"
    ),
    isAuthenticated: JSON.parse(localStorage.getItem("user")) ? true : false,
    chatVisible: JSON.parse(
      localStorage.getItem("chatVisible")
        ? localStorage.getItem("chatVisible")
        : false
    ),
  },
  {
    loginRequest: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.message = `Welcome ${action.payload.user.userName}, You have been logged in at Bellvue.`;
      localStorage.setItem("user", JSON.stringify(state.user));
      localStorage.setItem("token", JSON.stringify(state.token));
    },
    loginFail: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    },
    resetPasswordRequest: (state) => {
      state.loading = true;
    },
    resetPasswordSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.message = `Welcome ${action.payload.user.userName}, You have been logged in at Bellvue.`;
      localStorage.setItem("user", JSON.stringify(state.user));
      localStorage.setItem("token", JSON.stringify(state.token));
    },
    resetPasswordFail: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
    },
    forgetEmailRequest: (state) => {
      state.loading = true;
    },
    forgetEmailSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    },
    forgetEmailFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    signUpRequest: (state) => {
      state.loading = true;
    },
    signUpSuccess: (state, action) => {
      state.loading = false;
      state.message = "verification email has been sent please check email !";
    },
    signUpFail: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    },
    logOutRequest: (state) => {
      state.loading = true;
    },
    logOutSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("selectedPackage");
      state.user = null;
      state.message = action.payload.message;
    },
    logOutFail: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.error = action.payload;
    },
    setFTLRequest: (state) => {
      state.loading = true;
    },
    setFTLSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.message = `Welcome ${action.payload.user.userName}, You have been logged in at Bellevue Portal.`;
      localStorage.setItem("user", JSON.stringify(state.user));
      localStorage.setItem("token", JSON.stringify(state.token));
    },
    setFTLFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    suspendUserRequest: (state) => {
      state.loading = true;
    },
    suspendUserSuccess: (state, action) => {
      state.loading = false;
      state.signupMessage = action.payload.message;
    },
    suspendUserFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createUserRequest: (state) => {
      state.loading = true;
    },
    createUserSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    },
    createUserFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateProfileRequest: (state) => {
      state.loading = true;
    },
    updateProfileSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      localStorage.setItem("user", JSON.stringify(state.user));
      // state.message = "Profile Updated !";
    },
    updateProfileFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // getAllUserRequest: (state) => {
    //   state.loading = true;
    // },
    // getAllUserSuccess: (state, action) => {
    //   state.loading = false;
    //   state.allUsers = action.payload.users;
    // },
    // getAllUserFail: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // },
    billingInfo: (state, action) => {
      state.billTo = action.payload.data;
    },
    setPage: (state, action) => {
      state.page = action.payload.data;

      localStorage.setItem("page", JSON.stringify(state.page));
    },
    chatVisible: (state, action) => {
      state.chatVisible = action.payload.data;

      localStorage.setItem("chatVisible", JSON.stringify(state.chatVisible));
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  }
);
