import React from "react";
import { fontSizes } from "../../../constants/colors";
import { ConfigProvider, Tooltip } from "antd";

export function PrimaryHeading({ children, AdditionalStyle }) {
  return (
    <h1 className={` ${fontSizes.text_xl}  font-bold mb-5  ${AdditionalStyle} `}>
      {children}
    </h1>
  );
}

export function SecondaryHeading({ children, AdditionalStyle }) {
  return (
    <h1 className={` ${fontSizes.text_2_xl} capitalize font-medium ${AdditionalStyle}`}>
      {children}
    </h1>
  );
}
export function SubHeading({ children, style }) {
  return (
    <h1 className={` ${fontSizes.text_2_xl} uppercase comic !font-light `}>
      {children}
    </h1>
  );
}
export function CustomToolTip({ children, title }) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextLightSolid: "rgba(0, 0, 0, 0.88)",
        },
      }}
    >
      <Tooltip color="white" title={title}  >{children}</Tooltip>
    </ConfigProvider>
  );
}
