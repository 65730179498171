import { createReducer } from "@reduxjs/toolkit";


export const packageReducer = createReducer({
    selectedPackage: JSON.parse(localStorage.getItem("selectedPackage")) ,
    // selectedPackage: localStorage.getItem("selectedPackage") !== undefined
    // ? JSON.parse(localStorage.getItem("selectedPackage"))
    // : null,

}, {
    getPackageRequest: (state) => {
        state.loading = true
    },
    getPackageSuccess: (state, action) => {
        state.loading = false;
        state.packages = action.payload.packages || [];
    },
    getPackageFail: (state, action) => {
        state.loading = false
        state.packageError = action.payload
    },
    addPackageRequest: (state) => {
        state.loading = true
    },
    addPackageSuccess: (state, action) => {
        state.loading = false;
        state.packageMessage = action.payload.message
    },
    addPackageFail: (state, action) => {
        state.loading = false
        state.packageError = action.payload
    },
    deletePackageRequest: (state) => {
        state.loading = true
    },
    deletePackageSuccess: (state, action) => {
        state.loading = false;
        state.packageMessage = action.payload.message
    },
    deletePackageFail: (state, action) => {
        state.loading = false
        state.packageError = action.payload
    },
    editPackage: (state, action) => {
        state.editPackage = action.payload.data;
    },
    
    selectedPackage: (state, action) => {
        state.selectedPackage = action.payload.data;
        localStorage.setItem("selectedPackage", JSON.stringify(state.selectedPackage));
    },

    clearError: (state) => {
        state.packageError = null
    },
    clearMessage: (state) => {
        state.packageMessage = null
    }
})  