import React from "react";
import { PropagateLoader } from "react-spinners";

const PropagateLoading = ({ text }) => {
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto text-center relative">
      <div className="flex justify-center items-center overflow-hidden">
        <div className="flex flex-col items-center">
          <PropagateLoader  color="#36d7b7" />
          <span className="mt-10">{text}</span>
        </div>
      </div>
    </div>
  );
};

export default PropagateLoading;
